export default {
    "REGISTER_TITLE_1": "Register your account easily and strart trading.",
    "REGISTER": "Register",
    "ALREADY_HAVE_ACCOUNT": "Already have an account?",
    "ACCEPT_TERMS_MESSAGE": "Please accept the Terms & Conditions of Use",
    "RECOVERY_PASSWORD_TITLE": "Type in your registered password and we will send you a password recovery email.",
    "EMAIL_NOT_EXISTS": "User account not found",
    "SIGN_IN_BUTTON": "Sign In",
    "EMAIL_PLACEHOLDER": "Email Address",
    "PHONE_PLACEHOLDER": "Mobile Number",
    "ENTER_PASSWORD": "Enter Password",
    "KEEP_SIGN_COMPUTER": "Keep me signed in on this computer",
    "REMEMBER_ME": "Remember me",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "EMAIL_REQUIRED": "Email field is required",
    "EMAIL_INVALID": "Email is invalid",
    "INVALID_PASSWORD": "invalid Password",
    "ONLY_NUMERIC": "Only allow numeric value",
    "INVALID_OTP": "Invalid OTP",
    "FORGOT_PASSWORD": "Forgot password",
    "FORGOT_PIN": "Forgot pin",
    "SIGN_IN": "Sign up for Free",
    "PASSWORD_PLACEHOLDER": "Password",
    "PASSWORD_REQUIRED": "Password field is required",
    "PASSWORD_INCORRECT": "Password incorrect",
    "CHECK_OPTION": "Please check option",
    "HAVE_BACKUP": " I have backup the code",
    "PASSWORD_MIN_MAX": "Minimum 6 and maximum 18",
    "CONFIRM_PASSWORD_MISMATCH": "Passwords must match",
    "REGEX_PASSWORD": "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character",
    "PASSWORD_CHANGE_SUCCESS": "Password Reset Successfully",
    "PASSSWORD_RESET": "Password Reset",
    "PIN_RESET": "Pin Reset",
    "RESEND_VERIFICATION": "Resend Verification",
    "CURRENT_PASSWORD_REQUIRED": "Current Password field is required",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password field is required",
    "CONFIRM_PASSWORD": "Confirm Password",
    "UPDATE_PASSWORD": "Update Login Password",
    "LOGIN": "Login",
    "ORDER_HISTORY": "Order History",
    "DEFAULT_FIAT_CURRENCY": "Default Fiat Currency",
    "PAGE_AFTER_LOGIN": "Default Page After Login",
    "DEFAULT_LANGUAGE": "My Default Language",
    "REF_CODE_OPTION": "Referral Code (Optional)",
    "CURRENT_PASSWORD": "Current Password",
    "NEW_PASSWORD": "New Password",
    "CONFIRM_PASSWORD": "Confirm New Password",
    "NEW_PIN": "New Pin",
    "CONFIRM_PIN": "Confirm New Pin",
    "REFERRAL_CODE": "Reference Code",
    "I_AGREE": " I accept and agree to the",
    "REFERRAL": "Referral",
    "YOUR_REFERRAL_ID": "Your Referral ID",
    "INVITE_FRIENDS": "Invite Friends",
    "YOUR_TOTAL_REFERRAL": "Your Total Referral Income ",
    "TERMS": "Terms & Conditions",
    "AND": "and",
    "PRIVACY": "Privacy Policy",
    "PASSWORD": "Password",
    "OTP": "OTP",
    "DEFINITION": "Definitions",
    "TERMS": "Terms",
    "OUR_TERMS": "Our Terms",
    "TERMS_CONTENT_1": "These Corvus Terms of Use is entered into between you (hereinafter referred to as “you” or “your”) and Corvus operators (as defined below). By accessing, downloading, using or clicking on “I agree” to accept any Corvus Services (as defined below) provided by Corvus (as defined below), you agree that you have read, understood and accepted all of the terms and conditions stipulated in these Terms of Use (hereinafter referred to as “these Terms”) as well as our Privacy Policy at   https://www.Corvus.com/en/privacy. In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features.",
    "TERMS_CONTENT_2": "By accessing, using or attempting to use Corvus Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access Corvus or utilize Corvus services.",
    "TERMS_CONTENT_3": "Corvus refers to an ecosystem comprising Corvus websites (whose domain names include but are not limited to  https://www.Corvus.com), mobile applications, clients, applets and other applications that are developed to offer Corvus Services, and includes independently-operated platforms, websites and clients.",
    "TERMS_CONTENT_4": "Corvus Operators refer to all parties that run Corvus, including but not limited to legal persons, unincorporated organizations and teams that provide Corvus Services and are responsible for such services. For convenience, unless otherwise stated, references to “Corvus” and “we” in these Terms specifically mean Corvus ",
    "TERMS_CONTENT_5": " Corvus Services refer to various services provided to you by Corvus that are based on Internet and/or blockchain technologies and offered via Corvus websites, mobile applications, clients and other forms (including new ones enabled by future technological development). Corvus Services include but are not limited to such Corvus ecosystem components as Digital Asset Trading Platforms, the financing sector, Corvus Labs, Corvus Academy, Corvus Charity, Corvus Info, Corvus Launchpad, Corvus Research, Corvus Chain, Corvus X, Corvus Fiat Gateway, existing services offered by Trust Wallet and novel services to be provided by Corvus",
    "SEND_CODE": " Send Code",
    "RE_SEND_CODE": " Resend Code",
    "VERIFY_RECAPTCHA": "Verify recaptcha",
    "MOBILE_NO": "Mobile Number",
    "ENTER_MOBILE_NO": "Enter mobile number",
    "VERIFY_CODE": "Verification Code",
    "REQUIRED": "Required",
    "PRICE_ONLY_NUMERIC": "Price only numeric value",
    "FIRST_NAME_ALPHABET": "First name must be filled out with alphabets",
    "LAST_NAME_ALPHABET": "Last name must be filled out with alphabets",
    "BLOCKNO_NAME_ALPHABET": "Block name must be filled out with alphabets",
    "STATE_NAME_ALPHABET": "State must be filled out with alphabets",
    "CITY_NAME_ALPHABET": "City must be filled out with alphabets",
    "POSTAL_CODE_MUST_NUMBER": "Postal code Must be filled out with numbers",
    "INVALID_EMAIL": "Invalid email",
    "FILLED": "Filled",
    "ONLY_NUMERIC": "Only allowed numeric values",
    "ONLY_NUMERIC_POSITIVE": "Only allowed positive numeric values",
    "QUANTITY_REQUIRED": "Quantity field is required",
    "MAX_LIMIT_REQUIRED": "Max limit field is required",
    "PAYMENT_TYPE_REQUIRED": "Payment type field is required",
    "MAX_LIMIT_4": "Max limit 4",
    "ALLOW_NUMERIC_STOP_PRICE": "Stop price only numeric value",
    "ALLOW_POSITIVE_NUMERIC_STOP_PRICE": "Stop price only positive numeric value",
    "VALID_STOP_PRICE": "Please enter a valid stop price",
    "ALLOW_NUMERIC_PRICE": "Price only numeric value",
    "ALLOW_POSITIVE_NUMERIC_PRICE": "Price only positive numeric value",
    "VALID_PRICE_PRICE": "Please enter a valid price",
    "ALLOW_NUMERIC_QUANTITY": "Quantity only numeric value",
    "ALLOW_POSITIVE_NUMERIC_QUANTITY": "Quantity only positive numeric value",
    "VALID_QUANTITY": "Please enter a valid quantity",
    "INVALID_CODE": "Invalid Code",
    "INVALID_PIN": "Invalid Pin",
    "REQUIRED_6_DIGIT": "Required 6 Digit",
    "TERMS_REQUIRED": "Terms field is required",
    "PERSONAL_DETAILS": "Personal Details",
    "FULL_NAME": "Full Name",
    "RESIDENTIAL_ADDRESS": "Residential Address",
    "BUILDING_BLOCK": "Building / Block Name",
    "ADDRESS": "Address",
    "STATE_PROVISION": "State / Province",
    "CITY": "City",
    "POSTAL_CODE": "Postal Code",
    "COUNTRY": "Country",
    "PROOF": "Proof",
    "EDIT_PROFILE": "Edit my Profile",
    "Submit": "Submit",
    "FIRST_NAME": "First Name",
    "LAST_NAME": "Last Name",
    "NAME": "Name",
    "PROFILE_EDIT_SUCCESS": "Thank you, your profile has been updated!",
    "SOMETHING_WRONG": "Something went wrong",
    "NO_DATA": "There is no data",
    "NO_ORDER": "There is no order",
    "ORDER_CANCEL": "Your Order cancelled successfully",
    "ORDER_ALREADY_COMPLETED": "Your Order already completed",
    "ORDER_ALREADY_CANCEL": "Your Order already cancelled",
    "REGISTER_BANK_ACCOUNT": "Here you can register your bank account within the system which will allow you to withdraw funds from ##SITE_NAME##",
    "ADD_BANK_ACCOUNT": "Add Bank Account",
    "ADD_BANK": "Add Bank",
    "READ": "Read",
    "MY_BANK_ACCOUNT": "My Bank Accounts",
    "DEFAULT": "Default",
    "BANK_ACCOUNT": "Bank Account",
    "CHANGE": "change",
    "BANK_NAME": "Bank Name",
    "BANK_NAME_ALPHABET": "Bank Name must be filled out with alphabets",
    "CITY_ALPHABET": "City must be filled out with alphabets",
    "ACCOUNT_NO": "Account No",
    "ACCOUNT_NUMBER": "Account Number",
    "ACCOUNT_HOLDER_NAME": "Account Holder Name",
    "IBAN_SWIF": "IBAN/Swift Code",
    "IFSC_CODE": "IFSC Code",
    "IBAN_CODE": "IFSC Code",
    "INVALID_CURRENCY": "Currency is invalid",
    "BANK": "Bank",
    "STATUS": "Status",
    "CREATED_ON": "Create on",
    "TWO_FA": "Two Factor Authentication",
    "SECURITY_PIN": "Security Pin",
    "EDIT": "Edit",
    "DELETE": "Delete",
    "TWO_FA_MSG": "You have to enable 2FA in order to withdraw funds.",
    "PIN_SECURITY_MSG": "You have to enable Pin security in order to withdraw funds.",
    "BANK_EDIT_SUCCESS": "Bank details updated successfully",
    "BANK_ADD_SUCCESS": "Bank details added successfully",
    "BANK_DELETE_SUCCESS": "Bank detail deleted successfully",
    "BANK_SET_PRIMARY_SUCCESS": "Default bank detail updated successfully",
    "UPDATE": "Update",
    "NOTES": "Notes",
    "MIN_DEPOSIT_LIMIT": "Minimum deposit limit",
    "DEPOSIT_TIME": "Deposit time will take 4-24 hours due to bank network",
    "ZAAKPAY_DEPOSIT_NOTE": "AVAILABLE To pay via Mobikwik, top up your mobikwik wallet first using mobikwik app. (Credit Card top up cannot be used)Net banking, Debit Card & UPI | Automatic | Available 24x7 | Minimum deposit amount: INR 100 | Fee (inclusive of all taxes): As applicable on the gateway",
    "CHAT_ONLY_TRADE": "Chat only trade details info",
    "WITHDRAW_FIAT_DEPOSIT": "Withdraw Fiat Deposit",
    "CRYPTO_WITHDRAW": "Crypto Withdraw",
    "WITHDRAW_FIAT": "Withdraw Fiat",
    "MINIMUM_WITHDRAW": "Minimum withdraw amount is ##MINIMUM_WITHDRAW##",
    "AVOID_WORNG_WORDS": "Avoid unnecessary words",
    "TRANSFER_PAYMENT_BUTTON": " Click transfer payment button complete the payment to the seller",
    "NEED_SUPPORT": "Need Support?",
    "CREAT_SUPPORT_TICKET": "Create Support Ticket",
    "SUPPORT_DETAILS": "Support Details",
    "SUUPORT_ATTACH_FILE": "Max 9MB in jpg/png/pdf",
    "2FA_VERIFID": "2FA Code Verification",
    "PASSWORD_TITLE": "For security measures, your password should contain:",
    "PASSWORD_DESCRIPTION1": "a minimum of 6 characters long",
    "PASSWORD_DESCRIPTION2": "at least one UPPERCASE letter (A, B, X, Y...)",
    "PASSWORD_DESCRIPTION3": "at least one digit (0,1,2,9...)",
    "PASSWORD_DESCRIPTION4": "at least one special character (! @ # $ ^ *...)",
    "PASSWORD_DESCRIPTION5": "a maximum of 18 characters long",
    "PASSWORD_DESCRIPTION6": "at least one LOWERCASE letter (a,b,x,y...)",
    "TWO_FA_DESCRIPTION1": `Enable Two Factor Authentication as an additional security measure, to protect your funds and be able to withdraw. Please use Google Authenticator
    which you can`,
    "TWO_FA_DESCRIPTION2": `Please download the google authenticator by clicking "Download here" and scan the QR code. You will be receiving a 6 digit code, which need to enter in 2FA box.`,
    "TWO_FA_DESCRIPTION3": "Right down the 32 digit code in a white paper for your security if you forgot the 2FA code to withdraw funds",
    "DOWNLOAD_HERE": "Download Here",
    "HOW_ENABLE": "How to Enable",
    "ENABLE": "Enable 2FA",
    "DISABLE": "Disable 2FA",
    "ENTER_TWO_FA_CODE": "Enter 6 Digit 2FA Code",
    "ENTER_PIN_NUMBER": "Enter 6 Digit Pin Number",
    "TWO_FA_CODE": "Your 32 Digit Security Code",
    "ENTER_2FA": "Enter 2FA Code",
    "ENTER_PIN": "Enter your security pin number",
    "SCAN_QR_CODE": "Scan the QR Code",
    "DISABLED": "Disabled",
    "ENABLED": "Enabled",
    "TWO_FA_ENABLE_SUCCESS": "2FA enabled successfully",
    "TWO_FA_DISABLE_SUCCESS": "2FA disabled successfully",
    "SECURITY_PIN_ENABLE_SUCCESS": "Security pin enabled successfully",
    "SECURITY_PIN_DISABLE_SUCCESS": "Security pin disabled successfully",
    "NOT_MATCH": "Pin must match",
    "EDIT_SETTING_SUCCESS": "Settings updated successfully",
    "IDENTITY_DOCUMENT": "Identity Document",
    "PASSPORT_DOCUMENT": "Passport Document",
    "IDENTIFICATION_DOCUMENT": "Identification Document",
    "SELECTED_ID_NUMBER": "Enter the selected document ID number",
    "FRONT_SIDE": "Front Side",
    "PICTURE_SCAN": "Picture or scan showing the 4 corners",
    "MAX_1MB_IMG": "Max 1MB in jpg/png",
    "BACK_SIDE": "Back Side",
    "SIDE": "Side",
    "EXECTED_PRICE": "Executed Price",
    "EXECUTED": "Executed",
    "ADA": "ADA",
    "SELFIE_SELECTED_ID": "Selfie Image",
    "IDENTITY_DOCUMENT_TITLE1": "Here you can verify your identity by uploading one of the approved identity documents",
    "IDENTITY_DOCUMENT_DESCRIPTION1": "All Images should be clearly visible and text also clearly visible",
    "IDENTITY_DOCUMENT_DESCRIPTION2": "If not readable text, unclear face, blur image should be disqualified and the status will be NOT verified.",
    "IDENTITY_DOCUMENT_DESCRIPTION3": "The selfie or picture with  your selected ID must show clear face holding the selected ID. Please see an example",
    "IDENTITY_DOCUMENT_DESCRIPTION4": "If you want to change your identity document please contact our",
    "IDENTITY_HINT1": "Max. 9MB size, jpg, png, pdf allowed",
    "IDENTITY_HINT2": "Max 9MB in jpg/png/pdf",
    "HERE": "Here",
    "IDENTITY_DOCUMENT_VERIFIED": "Your ID document has been uploaded correctly",
    "PASSPORT_DOCUMENT_VERIFIED": "Your Passport document has been uploaded correctly",
    "PROFILE_MANDATORY_MSG": "It is mandatory to fill Personal Details before KYC Submission",
    "SUPPORT_TEAM": "support team",
    "TICKET_FOR": "Ticket For",
    "MSG_TO_SUPPORT_TEAM": "Message to Support Team",
    "ADDRESS_DOCUMENT_TITLE1": "Proof of Residential Address",
    "ADDRESS_DOCUMENT_TITLE2": "Address Proof Document",
    "ADDRESS_DOCUMENT_TITLE3": "Upload the document",
    "ADDRESS_DOCUMENT_DESCRIPTION1": "The document must clearly indicate your full name and full address.",
    "ADDRESS_DOCUMENT_DESCRIPTION2": "Document should NOT be older than 3 months",
    "ADDRESS_DOCUMENT_DESCRIPTION3": "Approved utility bill electricity bill, gas bill, landline phone, internet bill. Mobile phone bill NOT accepted. ",
    "ADDRESS_DOCUMENT_DESCRIPTION4": "Utility Bill (Approved utility bills: electricity bill, gas bill, landline phone, internet bill). Mobile phone not accepted.",
    "ADDRESS_DOCUMENT_DESCRIPTION5": "If you want to change your Address document please contact our",
    "ADDRESS_DOCUMENT_VERIFIED": "Your proof of address has been submited",
    "DRIVING_LICENSE": "Driving License",
    "UTILITY_BILL": "Utility Bill",
    "MIN_MAX_ERR": "Minimum 9 and maximum 18 characters",
    "INVALID_IFSC_CODE": "Invalid IFSC Code",
    "HOLDER_NAME_ALPHABET": "Holder name must be filled out with alphabets",
    "HOLDER_NAME_ERR": "Holder Name maximum 100 characters will be allowed",
    "BANK_STATEMENT": "Bank statement",
    "CREDIT_CARD_STATEMENT": "Credit card statement",
    "MUNICIPALITY_REGISTRATION": "Municipality Registration",
    "GOVERNMENT_ISSUED_ID": "Government issued ID",
    "PASSPORT": "Passport",
    "PANCARD": "Pancard",
    "GAS_BILL": "Gas Bill",
    "AADHAR_CARD": "Aadhar Card",
    "TOO_LARGE": "Too large",
    "INVALID_IMAGE": "Please select valid format",
    "IDENTITY_DOC_UPLOAD_SUCCESS": "Identity document updated successfully",
    "ADDRESS_DOC_UPLOAD_SUCCESS": "Residential document updated successfully",
    "NOT_APPLY": "Not Apply",
    "VERIFIED": "Verified",
    "NOT_VERIFIED": "Not Verified",
    "REJECTED": "Rejected",
    "BASIC": "Basic",
    "ADVANCED": "Advanced",
    "PRO": "Pro",
    "BASIC_PENDING": "Basic Pending",
    "BASIC_SUBMITTED": "Basic Submitted",
    "SUBMITTED": "Submitted",
    "BASIC_VERIFIED": "Basic Verified",
    "ADVANCED_Pending": "Advanced Pending",
    "PRO_PENDING": "Pro Pending",
    "ADVANCED_Pending": "Advanced Pending",
    "PRO_PENDING": "Pro Pending",
    "BASIC_USER": "Basic User",
    "ADVANCED_USER": "Advanced User",
    "PRO_USER": "Pro User",
    "TYPE_BASIC_DESCRIPTION1": "Deposit crypto up to 10,00 € in value",
    "TYPE_BASIC_DESCRIPTION2": "Trade crypto and fiat markets",
    "TYPE_BASIC_DESCRIPTION3": "Withdraw crypto and fiat up to 10,00  € in value for maximum $2500",
    "ID_ADDRESS_PROOF": "ID & Address Proof",
    "DEFAULT_BANK_ACCOUNT_DETAIL": "Bank Account Proof (only default withdraw bank)",
    "USER_ID": "User ID",
    "MEMBER_SINCE": "Member Since",
    "VERIFICATION_TYPE": "Verification Type",
    "EMAIL_PHONE": "Email Address & Phone",
    "VERIFY_NOW": "Verify Now",
    "PENDING": "Pending",
    "PHONE": "Phone",
    "TRANS_WALLET_AMOUNT": "Transfer Wallet Amount",
    "SELECT_BANK_ACCOUNT": "Select Bank Account",
    "WITHDRAW_ACCOUNT": "Withdraw Account",
    "INVALID_ADDRESS": "Invalid Address",
    "FIAT_WITHDRAW_DESCRIPTION1": "Minimal Withdraw",
    "FIAT_WITHDRAW_DESCRIPTION2": "Withdraw will take 24-48 hrs due to bank network / holidays",
    "FINAL_WITHDRAW_AMOUNT": "Final Withdraw Amount",
    "WALLET_BALANCE": "Wallet Balance ",
    "ENTER2FA_CODE": "Enter 2FA Code",
    "WALLET_TRANSFER": "Wallet Transfer",
    "MIN_WITHDRAW_LIMIT": "Minimum withdraw limit ##MIN_WITHDRAW## ##CURRENCY##",
    "MAX_WITHDRAW_LIMIT": "Maximum withdraw limit ##MAX_WITHDRAW## ##CURRENCY##",
    "WITHDRAW_FIAT_TIME_TAKE": "Withdraw time will take 4-24 hours due to bank network",
    "WITHDRAW_COIN_TIME_TAKE": "Withdraw time will take 24-48 hrs due to admin approve",
    "WITHDRAW_FEE": "Withdraw Fee ##WITHDRAW_FEE## ##CURRENCY##",
    "WITHDRAW_AMOUNT": "Withdraw Amount",
    "FUND_TRANSFER": "##CURRENCY## Fund Transfer",
    "RECEIVER_EMAIL": "Receiver Email",
    "RECEIVE": "I Will Receive",
    "WITHDRAW_ADDRESS": "Withdraw Address",
    "DEST_TAG": "Destination Tag",
    "ALLOW_NUMERIC": "Only allowed numeric",
    "QUANTITY_ONLY_NUMERIC": "Quantity only numeric value",
    "SIDE_REQUIRED": "Side field is REQUIRED",
    "INVALID_SIDE": "Invalid side",
    "MIN_LIMIT_REQUIRED": "Min limit field is required",
    // "MAX_LIMIT_REQUIRED": "Max limit field is REQUIRED",
    "SELECT_DATE": "Please Select date",
    "GREATER_THEN_CURRENT": "Please select Date and Time Greater than Current Date and Time",
    "LESS_LIMIT_PURCHASE": "From limit price should be less than to limit price",
    "ACCEPT_TERMS": "Please accept the terms and policy",
    "BALANCE": "Balance",
    "DISTANCE": "Distance",
    "INSUFFICIENT_BALANCE": "Insufficient balance",
    "KYC_SUBMIT_ALERT": "Please submit your kyc details",
    "INVALID_BANK_ACCOUNT": "Invalid bank account",
    "VERIFICATION_LINK": "Verification link sent to email address",
    "WAIT_FOR_ADMIN_APPROVE": "Your New withdraw request has been submitted. It will take 1-2 hours to credit in your account.",
    "INVALID_TOKEN": "Invalid Token",
    "EXPIRY_TOKEN": "Expiry token",
    "DEPOSIT_QR_CODE": "To deposit use this QR",
    "COIN_ADDRESS": "Your Coin Address",
    "COPY_ADDRESS": "Copy Address",
    "COIN_DEPOSIT_DESCRIPTION1": "Sending other than ##CURRENCY## to this address may result in the loss of your deposit.",
    "COIN_DEPOSIT_DESCRIPTION2": "Sending other than ##CURRENCY## token to this address may result in the loss of your deposit.",
    "CRYPTO_DEPOSIT_TIME": "Deposit time will take 15-20 Minutes due to ##CURRENCY## network",
    // "COIN_DEPOSIT_DESCRIPTION2": "Minimal Deposit: 0.001 ##CURRENCY##",
    // "COIN_DEPOSIT_DESCRIPTION1": "Send only LTC to this deposit address.",
    // "COIN_DEPOSIT_DESCRIPTION2": "Minimal Deposit: 0.001 LTC",
    "COIN_DEPOSIT_DESCRIPTION3": "Sending coin or token other than LTC to this address may result in the loss of your deposit.",
    "WITHDRAW_TOO_LOW": "Final amount too low",
    "FINAL_AMOUNT": "Final Amount",
    "FIAT_DEPOSIT_DESCRIPTION1": "Minimal Deposit: €10",
    "FIAT_DEPOSIT_DESCRIPTION2": "Must upload proof of deposit in pdf / jpg and enter reference number / ID",
    "ADMIN_ACCOUNT": "Admin Account",
    "DEPOSIT": "Deposit",
    "DEPOSIT_AMOUNT": "Deposit Amount",
    "UPLOAD_PROOF": "Upload Proof",
    "STAKE_AMOUNT": "Stake Amount",
    "T_HAVE_READ": "I have read the ",
    "DEPOSIT_REQUEST_SUCCESS": "Deposit request sent successfully",
    "DEPOSIT_TOO_LOW": "Deposit amount too low",
    "SELECT_CURRENCY": "Select Currency",
    "INVALID_WALLET_TYPE": "Invalid wallet type",
    "WALLET_MIS_MATCH": "From wallet and to wallet should be different",
    "TO_WALLET": "To Wallet",
    "FROM_WALLET": "From Wallet",
    "WALLET_TRANSFER_SUCCESS": "Wallet conversion process completed successfully",
    "OPEN_ORDER": "Open Orders",
    "CANCEL": "Cancel",
    "ORDERS": "Orders",
    "SUBSCRIBE_ETH": "Subscribe ETH",
    "LOT_AMOUNT": "Lot amount",
    "AVAILABLE_LOTS": "Available 10 Lots",
    "STAKE_LIMIT": "Stake Limit",
    "MINIMUM_LOT": "Minimum 1 Lot",
    "LOT_SIZE": "Lot size",
    "INTEREST_PER_LOT": "Interest per lot",
    "INDIVIDUAL_MAX": "Individual max",
    "VALUE_DATE": "Value date",
    "INTEREST_RATE": "Interest Rate",
    "INT_DURATION_TIME": "Interest Distribution Date",
    "REDEMPTION_DATE": "Redemption date",
    "EXPECT_INTEREST": "Expected interest",
    "HAVE_READ_AND_AGREE": "    I have read and I agree to the",
    "USER": "User",
    "CURRENT_SUBSCRIPTION": "Current Subscription",
    "TYPE": "Type",
    "RELEASE": "Release",
    "ENTER_CODE": "Enter code",
    "PRICE": "Per Price",
    "LATEST_PRICE": "Latest Price",
    "FLEXIBLE": "Flexible",
    "LOCKED": "Locked",
    "SUBSCRIBE": "Subscribe",
    "STOP_LIMIT": "Stop-limit",
    "STOP_MARKET": "Stop-market",
    "TRAILING_STOP": "Trailing-stop",
    "DEFAULT_THEME": "Default Theme for Trading",
    "LIGHT_THEME": "Light Theme",
    "DARK_THEME": "Dark Theme",
    "GENERAL_SETTINGS": "General Settings",
    "GENERAL": "General",
    "HIGH_YIELD": "High Yield",
    "CRYPTOS": "Cryptos",
    "VACATION": "Vacation",
    "DISABLE_ALL_ADVERTISMENT_SELL": "Disable all your advertisment for sell temprarily",
    "DISABLE_ALL_ADVERTISMENT_BUY": "Disable all your advertisment for buy temprarily",
    "YOUR_BALANCE": "Your Balance",
    "ESTIMATED_VALUE": "Estimated Value:",
    "BUSD": "BUSD",
    "BNB": "BNB",
    "TRX": "TRX",
    "XRP": "XRP",
    "OTHERS": "Other",
    "TOTAL_BALANCE": "Total Account Balance",
    "TRANSFER_FUND": "Transfer funds",
    "AMOUNT": "Amount",
    "WELCOME_TO": "Welcome to",
    "BANNER_DESCRIPTION_1": `We put the power in your hands to `,
    "BANNER_DESCRIPTION_2": "buy, sell and trade digital currency.",
    "GET_STARTED": "Get Started",
    "EXPLORE_MARKETS": "Explore Our Markets",
    "EXPLORE_MARKETS_DESCRIPTION_1": "The World's Leading Corvus Currency Exchange",
    "EXPLORE_MARKETS_DESCRIPTION_2": "Trade Bitcoin, ETH, and hundreds of other Corvus Currencies in minutes.",
    "BUY": "Buy",
    "DASHBOARD": "Dashboard",
    "ADMIN_ANNOUNCEMENT": "Admin Announcement",
    "LAST_LOGIN": "Last login",
    "ADMIN": "Admin",
    "ACCOUNT": "Account",
    "KYC_VERIFICATION": "KYC Verification",
    "EMAIL": "Email",
    "MOBILE_PHONE": "Mobile Phone",
    "2FA_TOOL_TIP1": "In order to withdraw funds,\nplease enable 2 Factor Authentication",
    "PIN_TOOL_TIP1": "In order to withdraw funds,\nplease enable security pin",
    "ENABLE_NOW": "Enable Now",
    "DISABLE_NOW": "Disable Now",
    "LOGIN_PASSWORD": "Login Password",
    "ALERT": "Alert",
    "WHEN_ENABLE_DISABLE_2FA": "When enable / disable 2FA",
    "WHEN_ENABLE_DISABLE_PIN": "When enable / disable PIN",
    "WHEN_LOGIN_PWD_CHG": "When login password change",
    "MARKTING_PROMOTION_EMAIL": "Markting and promotion email",
    "WALLET_BAL": "Wallet Balance",
    "AMOUNT_TO_STAKE": "Interested Amount to Stake",
    "SUBSCRIPTION_AMOUNT": "Subscription amount",
    "SUBSCRIPTION_DATE": "Subscription Date",
    "WITHDRAW": "Withdraw",
    "TRANSFER": "Transfer",
    "BTCUSDT": "BTCUSDT",
    "FIAT_HISTORY": "Fiat History",
    "CRYPTO_HISTORY": "Cryptocurrency History",
    "P2P_HISTORY": "P2P Order History",
    "ADV_FIAT_DEPOSIT": "Advanced Fiat Deposit upto",
    "ADV_FIAT_WITHDRAW": " Advanced Fiat Withdraw upto",
    "ALL_ADVANCE_FEATURE": "All Advanced Features",
    "PLUS": "PLUS",
    "FIAT_DEPOSIT": "Fiat Deposit",
    "DEPOSIT_BANK_INFO": "Deposit Bank Info",
    "SPOT_BALANCE": "Spot Balance",
    "ACCOUNT_FEATURES": "Full accounts features",
    "UNLIMITED_FIAT_DEP_WITH": "UNLIMITED Fiat Desposit and Withdraw",
    "DERIVATIVE_BAL": "Derivative Balance",
    "P2P_BAL": "P2P Balance",
    "STATISTICS": "Statistics",
    "TOTAL_SPOT_TRADE": "Total Spot Trade Completed",
    "TOTAL_DERIVATIVE_TRADE": "Total Derivatives Placed",
    "P2P_TRADE": "P2P Trade",
    "OPEN": "Open",
    "SPOT_TRADE": "Spot Trade",
    "DERIVATIVE_TRADE": "Derivative Trade",
    "2FA_PASS_UPDATE": "Two Factor & Password Update",
    "ESCROW_STATUS": "Escrow Status",
    "COMPLETED": "Completed",
    "RECENT_TRANSACTION": "Recent Transactions",
    "LOGIN_HISTORY": "Login History",
    "NOTIFICATION_HISTORY": "Notification History",
    "HISTORY": "History",
    "DATE": "Date",
    "TYPE": "Type",
    "SYMBOL": "Symbol",
    "TRANSACTION_REF": "Transaction Ref.",
    "NO_RECORD": "No Record",
    "POST_AD": "Post Ad",
    "MINUTES": "Minutes",
    "PLEASE_MAKE_PAYMENT": "Please make a payment within 30:00 mins, otherwise, the order will be cancelled automatically.",
    "TRADE_LIMIT": "Trade Limit",
    "TRANSFER_PAYMENT": "Transfer Payment",
    "RELEASE_ASSET": "Release Asset",
    "DISPUTE_TRADE": "Dispute Trade",
    "GENERAL": "General",
    "RLY_TO_ADMIN": "Reply to admin",
    "CLOSE_THE_TICKET": "Satisfied, Close this ticket",
    "WANT_PAY": "I Want to Pay",
    "WANT_SELL": "I Want to Sell",
    "CANCEL_ORDER": "Cancel Order",
    "WILL RECEIVE": "I Will receive",
    "CALNEL_AD": "  Are you sure want to cancel the Ad?",
    "IP_ADDRESS": "IP Address",
    "LOCATION": "Location",
    "DEVICE": "Device",
    "FIAT_CRYPTO": "Fiat/Crypto",
    "TOTAL": "Total",
    "FIAT_WALLET": "Fiat Wallet",
    "CRYPTO_WALLET": "Crypto Wallet",
    "SPOT": "SPOT",
    "DERIVATIVE": "DERIVATIVE",
    "P2P": "P2P",
    "TRIGGER_CONDITION": "Trigger Conditions",
    "SELL_BITCOIN": "Sell Bitcoin",
    "BUY_BITCOIN": "Buy Bitcoin",
    "BITCOIN": "Bitcoin",
    "ETHEREUM": "Ethereum",
    "TOTAL_STAKING": "Total Staking",
    "RIPPLE": "Ripple",
    "LITE_COION": "Litecoin",
    "TETHER_COIN": "TetherCoin",
    "FIND_COIN": "Find Coin",
    "WALLET": "Wallet",
    "FUND": "Fund",
    "HOME": "Home",
    "FEES": "Fees",
    "ACTIONS" : "Actions",
    "FAQ": "FAQ",
    "TERMS_CONDITION": "Terms & Conditions",
    "TRANSFER_CONFIRM": "Transfer Confirm",
    "STAKING_DELAILS": "Staking Details",
    "DAY_APY": "Day APY",
    "YESTERDAY_APY": "Yesterday's Flexbile APY",
    "FLEXIBLE_INTERESTED": "Flexible Interest Per Thousand",
    "PRIVACY_POLICY": "Privacy Policy",
    "REG_EMAIL_MOBILE": "Your Registered Email Address/ Mobile Number",
    "DOWNLOAD_APP": "Download app from",
    "MARKETS": "Market",
    "SPOT_MARKET": "Spot Market",
    "DERIVATIVE_MARKET": "Derivative Market",
    "POLICY": "policy",
    "SUBMIT_POST": "Submit Post",
    "POST_ID": "Post ID",
    "POSTED_ON": "Posted on",
    "POST_END_DATE": "Post End Date",
    "MARKET": "Market",
    "SUPPORT": "Support",
    "CONTACT_US": "Contact us",
    "CONTACT_PARAGRAPH_1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "SUPPORT_TICKET": "Support Ticket",
    "NEWS_UPDATE_TITLE": "Get the latest news and updates",
    "COPY_RIGHT": "Copy right ©{{YEAR}} - Corvus All rights reserved",
    "CONNECT_WITH_US": "Connect with us",
    "CONTACT": "Contact",
    "PROFILE": "Profile",
    "PERSONAL": "Personal",
    "MY_ACCOUNT": "My account",
    "LOGOUT": "Logout",
    "KYC": "KYC",
    "CONTACT_DETAILS": "Contact Details",
    "BANK_ACCOUNT_DETAIL": "Bank Account Details",
    "PROFILE_DESCRIPTION1": "Your personal details should be introduced strictly AS INDICATED IN YOUR OFFICIAL IDENTIFICATION DOCUMENTS AND PROOF OF ADDRESS. If you have any doubts/issues, please raise a support ticket.",
    "PROFILE_IMAGE": "Profile Image",
    "FILE_FORMAT1": "jpg/jpeg/png max {{SIZE}}",
    "CHANGE_EMAIL": "Change Email",
    "UPDATE_EMAIL": "Update Email",
    "PHONE_NUMBER": "Phone Number",
    "SEND_OTP": "Send OTP",
    "RESEND_OTP": "Resend OTP",
    "ENTER_OTP": "Enter OTP",
    "ENTER_OTP_CODE": "Enter OTP Code",
    "SEC": "Sec",
    "UPDATE_PHONE_NO": "Update Phone Number",
    "ADD_ANOTHER_ACCOUNT": "Add Another Account",
    "WHY_COIN": "Why Coin",
    "PARAGRAPH_1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "GOLD": "Gold",
    "SPOT_TRADE": "Spot Trading",
    "PARAGRAPH_2": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "START_TRADE": "Start Spot Trading",
    "DERIVATIVE_TRADE": "Derivative Trading",
    "PARAGRAPH_3": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "START_DERIVATIVE": "Start Derivative Trading",
    "LIGHT": "Light",
    "MODE": "Mode",
    "DARK": "Dark",
    "COIN": "Coin",
    "FEATURE": "Features",
    "FREE": "Free",
    "INSTANT": "Instant",
    "TRADING": "Trading",
    "FIAT_EASILY": "Fiat Easily",
    "PROMPT": "Prompt",
    "KYC_VERIFY": "KYC Verify",
    "SECURE_PLATFORM": "Our Secured Platform",
    "2FA_PROTECT": "2FA Protected",
    "PARAGRAPH_4": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "PARAGRAPH_5": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "PARAGRAPH_6": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "256-BIT": "256-bit Encryption",
    "SECURE_WALLET": "Secured Wallet",
    "JOIN_REFERRAL_HEADING": "Join our referral Program",
    "EARN_MONEY": "Start earning money by inviting friends",
    "JOIN_REFERRAL": "Join Our Referral ",
    "GET_STARTED_HEADING": "Get started in a few minutes",
    "SIGN_UP": "Sign up",
    "ACCOUNT_ACCESS": "Instant 2 min account access",
    "VERIFY": "Verify",
    "PASSWORD_ADDRESS": "with Passport & Address proof",
    "TRADE": "Trade",
    "PAIR_PROFIT": "with Multi pair & book profit",
    "TRADE_WITH": "Trade With Mobile App",
    "FOOTER_P": "Compatible with multiple devices, start trading with safety and convenience.",
    "P2P_TRADING": "P2P Trading",
    "PARAGRAPH_7": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
    "I_WANT_TO": "I Want to ",
    "ACCOUNT_FROZEN": " Malicious clicks will lead to account frozen",
    "SELL": "Sell",
    "BUY": "Buy",
    "PAY_WITH_TIME_LIMIT_LIVE": "Pay within the time limit, otherwise the order cancelled automatically 30 Minutes",
    "PAY_WITH_TIME_LIMIT_STAGING": "Pay within the time limit, otherwise the order cancelled automatically 1 Minutes",
    "PAY_TRADE_TERMS": "Only pay as per trade terms",
    "CONFIRM_PAYMENT": "Confirm Payment",
    "PAYMENT_MADE_SELLER": "Please confirm that payment has been made to the seller.",
    "SELECT_CRYPTO": "Select Crypto",
    "AMOUNT": "Amount",
    "PAYMENT_TYPE": "Payment Type",
    "START_p2p": "Start P2P",
    "EMAIL": "Email",
    "NOT_VERIFIED": "Not Verified",
    "ID_DOCUMENT": "ID Document:",
    "ADDRESS_PROOF": "Address Proof:",
    "2FA_HEADING": "2 Step Authentication",
    "DIGITAL_CODE": "Enter 2FA Six Digit Code",
    "PASTE_CODE": "Paste Code",
    "CONFIRM": "Confirm",
    "SECURITY_SETTINGS": "Security Settings",
    "POST_NEW_AD": "Post New Ad",
    "P2P_STATISTICS": "P2P Statistics",
    "TOTAL_TRADES": "Total Trades",
    "FIRST_TRADE": "First Trade",
    "TRADES": "trades",
    "COMPLETION": "completion",
    "AVL": "Avl",
    "CREATED_TIME": "Created Time",
    "ORDER_ID": "Order ID",
    "ATTACHMENT": "Attachment",
    "MORE_ABOUT_SUPPORT": "More About Support",
    "LOGIN_ISSUE": "Login Issue",
    "DEPOSIT_ISSUE": "Deposit Issue",
    "WITHDRAW_ISSUE": "Withdraw Issue",
    "ADMIN_RLY": "Admin reply on",
    "CONFIRM_MY_POST": "Confirm My Post",
    "FILTER_BY": "Filter by",
    "REPLAY": "Reply",
    "BACK": "Back",
    "YOUR_API_KEY": "Your API Keys",
    "ID": "Id",
    "CREATED": "Created",
    "PERMISSION": "Permissions",
    "ACTION": "Action",
    "STAKE_NOW": "  Stake Now",
    "LOADING": "Loading...",
    "INVALID_URL": "Invalid Url",
    "TRANSFER_BITCOIN": "Transfer Bitcoin",
    "TICKET_ID": "Ticket ID",
    "POST_HISTORY": "Post History",
    "REF_KEY_LATER": "Optional name for this key. This will help you reference this key later.",
    "IP_RESTRICTION": "IP access restrictions:",
    "SECURITY_REASONS": "For security reasons, please do not reveal your key(s) to non-trusted third parties.We recommend that users add their IP addresses to each of the API key. A maximum of four (4) IP addresses can be added to each key. To add multiple IP addresses, enter them seperately with commas in between. e.g.192.168.1.1,192.168.1.2,192.168.1.3",
    "CREATE_API_KEY": "  Create API Key",
    "WRITE_SECRET_KEY": "Please write down the secret key.",
    "SOMEWHERE_SAFE": "Write it down somewhere safe! The secret key will no longer be available once you leave this page.",
    "SECRET": "Secret",
    "USD": "USD",
    "EURO": "Euro",
    "ADD_ANOTHER_ACCOUNT": "    Add Another Account",
    "CRYPTO": "Crypto",
    "LAST_PRICE": "Last Price",
    "FIAT": "Fiat",
    "YOUR_FULL_NAME": "Your Full Name",
    "SUBJECT": "Subject",
    "MESSAGE": "Message",
    "CONTRACTS": "Contracts",
    "FILLED_TOTAL": "Filled/Total",
    "FILLED_PRICE": "Filled Price",
    "FILLED": "Filled",
    "TRADE_HISTORY": "Trade History",
    "ORDER_PRICE": "Order Price",
    "TRADE_TYPE": "Trade Type",
    "ORDER_TYPE": "Order Type",
    "ORDER_NO": "Order No.",
    "Download_PDF": "Download PDF",
    "CANCEL_POST": "Cancel My Post",
    "ORDER_TIME": "Order Time",
    "NO_DATA": "No data Available ",
    "SIZE": "Size",
    "TAKER_PRICE": "Taker Profit",
    "STOP_LOSS": "Stop Loss",
    "GTC": "GTC",
    "IOC": "IOC",
    "FOK": "FOK",
    "LONG": "Long",
    "SHORT": "Short",
    "VALUE": "Value",
    "COST": "Cost",
    "TP_SL": "TP/SL",
    "24H_CHANGE": "24h Change",
    "24_HIGH": "24h High",
    "24_LOW": "24h Low",
    "24H_VOLUME": "24h Volume",
    "QTY": "Qty",
    "ORDER_BOOK": "Order Book",
    "PLACE_ORDER": "Place Order",
    "LIMIT": "Limit",
    "PERPETUAL": "Perpetual",
    "BTC_PAIRS": "BTC Pairs",
    "ETH_PAIRS": "ETH PAirs",
    "PAIR": "Pair",
    "MARKET_CLOSE": "Market Close",
    "CLOSE_QTY": "Close Qty USD",
    "5CONTRACT_CLOSE": "5 Contracts(s) Will be closed at last Traded price, and your expected profit will be ",
    "INCLUSIVE": "Inclusive of est.colsing fees",
    "FEES": "Fee",
    "FEE_CONTENT": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    "ENTYRY_PRICE": "Entry Price",
    "IDENTITY_VERIFICATION": "Identity Verification",
    "LIQ_PRICE": "Liq. Price",
    "POSITION_MARGIN": "Position Margin",
    "UNREALIZED": "Unrealized P&L (%)",
    "DAILY_REALIZED": "Daily Realized P&L",
    "TRAILING_STOP": "Trailing Stop",
    "CLOSED_BY": "Close By",
    "RECENT_TRADE": "Recent Trades",
    "TIME": "Time",
    "STOP_PRICE": "Stop Price",
    "LIMIT_PRICE": "Limit Price",
    "POSITION": "Position",
    "EDIT_MY_POST": "Edit My Post",
    "CRYPTO_CURRENCY": "Cryptocurrency",
    "SELECT_COIN": "Select coin",
    "Quantity": "Quantity",
    "PRICE_CURRENCY": "Price Currency",
    "CURRENCY_MARKET_PRICE": "Current Market Price",
    "PRICE_LIMIT_MARKET": "Price Limit From ",
    "SHOW_MY_POST": "Show My Post Till",
    "PERFERRED_PAYMENTS": "Preferred Payments",
    "PAYMENT_TO_MADE": "Payment to be made",
    "PRICE_LIMIT_FROM": "Price Limit From",
    "PRICE_LIMIT_TO": "Price Limit To",
    "SELECT_PAYMENT": "Select Payment",
    "DURATION_DAYS": "Duration (Days)",
    "I_AGREE_ESCROW": "I agree to Escrow my crypto funds and accept  the",
    "DURATION": "Duration",
    "ALL": "All",
    "ETH": "ETH",
    "APY": "APY",
    "XRB": "XRB",
    "SPOT_WALLET": "Spot Wallet",
    "DERIVATIVE_WALLET": "Derivative Wallet",
    "P2P_WALLET": "P2P Wallet",
    "FORM": "From",
    "QUANTITY": "Quantity",
    "FLEX_STAKING": "Flexible  Staking",
    "LOCKED_STAKING": "Locked Staking",
    "MIN_SUBSCRIPTION": "Minimum subscription",
    "SUBSCRIPTION": "Subscription",
    "MAX_SUBSCRIPTION": "Maximum subscription",
    "STAKING_HISTORY": "Staking History",
    "REDEMPTION_PERIOD": "Redemption Period",
    "REDEMPTION": "Redemption",
    "READ_AND_AGREE": "I have read and I agree to the",
    "STAKING_TERMS": "Staking Terms",
    "INTEREST": "Interest",
    "STAKING": "Staking",
    "BTC/USD": "BTC/USD",
    "DIGITAL_ASSETS": "Leading Digital Assets ",
    "ONE_PLATFORM": "Under One Platform",
    "MATKET_PRICE": "Market Price",
    "TRADE": "Trade",
    "SPOT_ORDERS": "Spot Orders",
    "DERIVATIVE_ORDERS": "Derivative Orders",
    "P2P_ORDERS": "P2P Orders",
    "STAKING_ORDERS": "Staking Orders",
    "MANA": "MANA",
    "BTC": "BTC",
    "DAYS": "Days",
    "TOTAL_VOLUME": "Total Volume",
    "TOTAL_TRADE_COMPLETE": "Total Trade Complete",
    "ENTER_AMOUNT": "Enter Amount",
    "VIEW_CHAT": "View/Chat",
    "PAY_BY": "Pay By",
    "TOTAL": "Total",
    "ALL_PAYMENT": "All Payment",
    "AVAILABLE": "Available Quantity",
    "SELLER_PAYMENT_TYPE": "Seller’s Payment Type",
    "PAYMENT_TIME_LIMIT": "Payment Time Limit",
    "TYPE": "Post Type",
    "RECENT_POST": "Recent Post",
    "TO": "To",
    "DEPOSIT_CURRENCY": "Deposit Currency",
    "YOUR": "Your",
    "WALLET_ADDRESS": "Wallet Address",
    "SCAN_QR_CODE": "Scan QR Code",
    "MEMO_TAG": "Memo Tag",
    "TOKEN_PURCHASE_EXPIRED": "Token purchase expired",
    "MINIMUM_QUANTITY": "Amount is greater than minimun amount",
    "TOKEN_PURCHASE_SUCCESS": "Token purchase success",
    "INSUFFICIENT_BALANCE": "Insufficient Balance",
    "NOT_AVAILABLE_BALANCE": "Not available Balance",
    "SOMETHING_WRONG": "Something wrong",
    "VERIFY_MOBILE_ERR": "Please verify your mobile number",
    "INVALID_COIN": "Invalid coin",
    "PRICE_REQUIRED": "Price field is required",
    "UPI_ACCOUNT" : "UPI Account",
    "UPI_ID" : "UPI ID",
    "UPI_DETAILS" : "UPI Details",
    "ADD_UPI_ACCOUNT" : "Add UPI Account",
    "UPI_NAME" : "UPI ID",
    "GPAY_DETAILS" : "G-Pay Details",
    "GPAY" : "G-Pay",
    "QR_NAME" : "QR Image",
    "QR_ACCOUNT" : "Gpay Account",
    "ADD_QR_ACCOUNT" : "ADD QR ACCOUNT",
    "KYC_REMINDER" : "KYC Reminder"
}