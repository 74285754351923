// import package
import React, { useEffect } from 'react';

// import component
import GridItem from "components/Grid/GridItem.js";
import CryptoWallet from './CryptoWallet';
import FiatWallet from './FiatWallet';

// import action
import { checkDeposit } from '../../actions/walletAction'

let myInterval;
const WalletList = () => {

    useEffect(() => {
        myInterval = setInterval(() => {
            checkDeposit()
        }, 20000);
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(myInterval);
        };
    }, []);

    return (
        <GridItem xs={12} sm={12} md={7} lg={9}>
            <FiatWallet />
            <CryptoWallet />
        </GridItem>
    )
}

export default WalletList