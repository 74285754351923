// import package
import React, { useEffect, useInsertionEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux'

// import components
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Home from '../components/Home';
import P2pTrading from '../components/Home/P2pTrading';
import FaqTrend from '../components/Home/FaqTrend';

// import action
import { getLanguage, getAllCMSPage } from '../actions/commonAction';

// import lib
import isEmpty from "../lib/isEmpty";
import { useTranslation } from 'react-i18next';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const HomePage = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [spot, setSpotTrade] = useState('')
  const [derivative, setDerivative] = useState('')
  const [p2p, setP2p] = useState('')
  const [encrypt, setencrypt] = useState('')
  const [wallet, setWallet] = useState('')
  const [p2pTrade, setP2pTrade] = useState('')
  const [coin, setSetCoin] = useState('')
  // redux
  const { isAuth } = useSelector(state => state.auth)
  const language = useSelector(state => state.language)

  const fetchCmsPage = async () => {
    try {
      const findLang = localStorage.getItem('lang')
      let data = {
        lang: findLang
      }
      const { status, loading, result } = await getAllCMSPage(data);
      if (status == 'success') {
        let spotTrade = result.find(item => item.identifier == 'home_spot')
        setSpotTrade(spotTrade)
        let derivativeTraded = result.find(item => item.identifier == 'home_derivative')
        setDerivative(derivativeTraded)
        let p2pContent = result.find(item => item.identifier == '2fa_protected')
        setP2p(p2pContent)
        let encryption = result.find(item => item.identifier == 'home_encryption')
        setencrypt(encryption)
        let wallet = result.find(item => item.identifier == 'home_wallet')
        setWallet(wallet)
        let p2pTrading = result.find(item => item.identifier == 'home_p2p')
        setP2pTrade(p2pTrading)
        let coinContent = result.find(item => item.identifier == 'home_coin')
        setSetCoin(coinContent)
      }
    } catch (err) { }
  }

  // identifier







  // function
  useEffect(() => {
    if (isEmpty(language)) {
      getLanguage(dispatch)
    }
  }, [])
  useEffect(() => {
    fetchCmsPage()
  }, [])
  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />      

      <Home />



      <section className="youtupe_video_section">
        <div className="container">
          <GridContainer>
            <GridItem className="col-md-7">
              <h1 data-aos="fade-up" data-aos-duration="1000">Start Trading on CORVUS EXCHANGE</h1>
              <p data-aos="fade-up" data-aos-duration="1000">Compatible with multiple devices, start trading with safety and convenience.</p>
              <div className="start_trading_panel" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                <img src={require("../assets/images/icon_4.png")} alt="logo" className="img-fluid" />
                <div>
                  <h5>Secure Storage</h5>
                  <p>We take security very seriously at Corvus and utilize our secure cold-storage technology.</p>
                </div>
              </div>
              <div className="start_trading_panel" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
                <img src={require("../assets/images/icon_5.png")} alt="logo" className="img-fluid" />
                <div>
                  <h5>24/7 Support</h5>
                  <p>We provide round the clock service through our ticketing system.</p>
                </div>
              </div>
              <div className="start_trading_panel" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
                <img src={require("../assets/images/icon_6.png")} alt="logo" className="img-fluid" />
                <div>
                  <h5>Earn Extra Income</h5>
                  <p>Trade cryptos with fast transactions and zero downtime to earn extra income.</p>
                </div>
              </div>
            </GridItem>                      
            <GridItem className="col-md-5" data-aos="fade-up" data-aos-duration="1000">
              <img src={require("../assets/images/img_5.png")} alt="logo" className="img-fluid" />
            </GridItem>
          </GridContainer>
        </div>
      </section>

      <section className="secured_platform_section">
        <div className="container">
          <h1 className="titlt_blue" data-aos="fade-up" data-aos-duration="1000">The Most Trusted Cryptocurrency Platform</h1>
          <div className="row">
            <div className="col-lg col-md-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <div className="secuer_section_contnt">
                <img src={require("../assets/images/icon_7.png")} alt="logo" className="img-fluid" />
                <h3>Reliability</h3>                
              </div>
            </div>
            <div className="col-lg col-md-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <div className="secuer_section_contnt">
                <img src={require("../assets/images/icon_8.png")} alt="logo" className="img-fluid" />
                <h3>Security</h3>

              </div>
            </div>
            <div className="col-lg col-md-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <div className="secuer_section_contnt">
                <img src={require("../assets/images/icon_9.png")} alt="logo" className="img-fluid" />
                <h3>Governance</h3>
              </div>
            </div>
            <div className="col-lg col-md-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">
              <div className="secuer_section_contnt">
                <img src={require("../assets/images/icon_10.png")} alt="logo" className="img-fluid" />
                <h3>More Profit</h3>
              </div>
            </div>
            <div className="col-lg col-md-3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              <div className="secuer_section_contnt">
                <img src={require("../assets/images/icon_11.png")} alt="logo" className="img-fluid" />
                <h3>Easy Exchange</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="referal_program_section">
        <div className="container">
          <div className="st_trade_bg">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-8 col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                <div className="referl_section">
                  <h1>Start Trading on CORVUS EXCHANGE</h1>
                  <p>Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.</p>                  
                </div>
              </div>
              <div className="col-md-4 col-lg-3" data-aos="fade-up" data-aos-duration="1000">
                <Link class="btn_bg mr-2" to="/spot">Start Trade</Link>
              </div>
            </div>
          </div>          
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default HomePage;