// import package
import React, { useEffect, useState } from 'react';
import { Button, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import component
import FiatDeposit from './FiatDeposit';
import FiatWithdraw from './FiatWithdraw';
import WalletTransfer from './WalletTransfer';
import ZaakpayDeposit from './ZaakpayDeposit';
import CashFreeWithdraw from './CashFreeWithdraw';
import PayatMeWithdraw from './PayatMeWithdraw';

// import lib
import { toastAlert } from '../../lib/toastAlert';
import { toFixed } from '../../lib/roundOf';
import { kycStatus } from 'lib/displayStatus';
import isEmpty from 'lib/isEmpty';

const FiatWallet = () => {
    const { t, i18n } = useTranslation();

    // state
    const [model, setModal] = useState({
        type: '',
        assetData: {},
        currency: {}
    })
    const [kycStatus, setKycStatus] = useState(false);
    const [walletType, setWalletType] = useState('spot')

    // redux-state
    const walletData = useSelector(state => state.wallet);
    const currencyDoc = useSelector(state => state.currency);
    const userKycData = useSelector(state => state.userKyc);
    const userData = useSelector(state => state.account);

    const { idProof, addressProof } = userKycData;
    const { country } = userData;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setWalletType(value)
    }

    const modalClose = () => {
        setModal({
            type: '',
            assetData: {},
            currency: {}
        })
    }

    useEffect(() => {
        let status = false;
        if (country && country == 'India' && idProof && idProof.status == 'approved' && addressProof.status == 'approved') {
            status = true;
        } else if (country && country != 'India' && idProof && idProof.status == 'approved') {
            status = true;
        }

        setKycStatus(status)
    }, [country, idProof, addressProof])

    // console.log(userData,'userDatauserDatauserData')

    return (
        <div className="p2p_card min-h-auto input_box-flex">
            <FiatDeposit
                show={model.type == 'deposit'}
                assetData={model.assetData}
                currency={model.currency}
                onHide={modalClose}
            />
            <FiatWithdraw
                show={model.type == 'withdraw'}
                assetData={model.assetData}
                currency={model.currency}
                onHide={modalClose}
            />
            <WalletTransfer
                show={model.type == 'walletTransfer'}
                assetData={model.assetData}
                onHide={modalClose}
            />
            {/*<ZaakpayDeposit
                show={model.type == 'deposit'}
                assetData={model.assetData}
                currency={model.currency}
                onHide={modalClose}
            />*/}
            {/*<CashFreeWithdraw
                show={model.type == 'withdraw'}
                assetData={model.assetData}
                currency={model.currency}
                onHide={modalClose}
            />*/}
            {/*<PayatMeWithdraw
                show={model.type == 'withdraw'}
                assetData={model.assetData}
                currency={model.currency}
                onHide={modalClose}
            />*/}
            {/*<h3 className="login_title_8">{t("FIAT_WALLET")}</h3>*/}
            <div className="d-flex justify-content-between align-items-center mb-3 pb-3">
                <h3 className="login_title_8 mb-0 pb-0">{t("FIAT_WALLET")}</h3>
                    <div className="seacr_box_s">
                        <Select
                            name="walletType"
                            value={walletType}
                            label="Locked"
                            onChange={handleChange}
                        >
                            <MenuItem value={'spot'}>{t("SPOT")}</MenuItem>                        
                            <MenuItem value={'p2p'}>{t("P2P")}</MenuItem>
                            {/* <MenuItem value={'derivative'}>{t("DERIVATIVE")}</MenuItem> */}
                        </Select>
                    </div>
                {/* <div className="seacr_box_s">
                    <input type="text" placeholder={t("FIND_COIN")} />
                    <i class="fas fa-search"></i>
                </div> */}
            </div>
            <div className="fialt_wallet_sectoin table-responsive">
                {
                    currencyDoc && currencyDoc.length > 0 && walletData && walletData.length > 0 && walletData.map((item, key) => {
                        let curData = currencyDoc.find(el => el.coin == item.coin)

                        if (curData && ['fiat'].includes(curData.type)) {
                            return (
                                <div className="fiat_wallet_list">
                                    <div>
                                        <div>
                                            <img src={curData && curData.image} alt="logo" className="img-fluid" />
                                            <span>{item.coin}</span>
                                        </div>
                                        { walletType == 'spot' && <p>{toFixed(item.spotBal, curData.decimal)}</p> }
                                        { walletType == 'p2p' && <p>{toFixed(item.p2pBal, curData.decimal)}</p> }
                                    </div>
                                    {/* {  curData && curData.status == 'active' &&  */}
                                
                                    <div className="button_lst_section">
                                    { walletType == 'spot' &&  
                                        <div className={`Subscribe ${((curData && curData.depositStatus == 'off') || (curData.status != "active")) && "Subscribe_disabled"}`}>
                                            <Button className="btn-primary" onClick={() => {
                                                if (!kycStatus) {
                                                    toastAlert('error', 'Please submit your kyc', 'FIAT_DEPOSIT')
                                                } else if (userData && userData.emailStatus != 'verified') {
                                                    toastAlert('error', 'Please update your email', 'FIAT_DEPOSIT')
                                                } else {
                                                    setModal({
                                                        type: 'deposit',
                                                        assetData: item,
                                                        currency: curData,
                                                    })
                                                }
                                            }}
                                                disabled={((curData && curData.depositStatus == 'off') || (curData.status != "active"))}
                                            >{t("DEPOSIT")}</Button>
                                        </div>
                                    }
                                
                                    { walletType == 'spot' &&        
                                        <div className={`Subscribe ${(curData && curData.withdrawStatus == 'off') || (curData.status != "active") || (isEmpty(userData.withdrawRestrict) || !userData.withdrawRestrict.includes(curData._id)) && "Subscribe_disabled"}`}>
                                            <Button className="btn-primary"
                                                onClick={() => {
                                                    if (!kycStatus) {
                                                        toastAlert('error', 'Please submit your kyc', 'FIAT_WITHDRAW')
                                                    } else if (userData && userData.emailStatus != 'verified') {
                                                        toastAlert('error', 'Please update your email', 'FIAT_WITHDRAW')
                                                    } else if (userData && userData.phoneStatus != 'verified') {
                                                        toastAlert('error', 'Please update your mobile number', 'FIAT_WITHDRAW')
                                                    } else {
                                                        setModal({
                                                            type: 'withdraw',
                                                            assetData: item,
                                                            currency: curData,
                                                        })
                                                    }
                                                }}
                                                disabled={(curData && curData.withdrawStatus == 'off') || (curData.status != "active") || (isEmpty(userData.withdrawRestrict) || !userData.withdrawRestrict.includes(curData._id))}
                                            >{t("WITHDRAW")}</Button>
                                        </div>
                                    }        
                                        <div className="Subscribe">
                                            <Button className="btn-primary" onClick={() => {
                                                setModal({
                                                    type: 'walletTransfer',
                                                    assetData: item,
                                                    currency: curData
                                                })
                                            }}>{t("TRANSFER")}</Button>
                                        </div>
                                    </div>
                                    {/* }     */}
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export default FiatWallet;